import { createStore } from 'vuex'
import { getChainId,setChainId,getToken ,setToken} from '@/utils/auth'
import {MemberView,Loginout} from '@/api'
import {router} from '@/router'



export const store = createStore({
  state: {
    isConnect:false,
    Token:'',
    chain:'',
    address:"",
    member:'',
  },
  mutations: {
    set_Connect:(state, data)=>{
      state.isConnect = data
    },
    set_Token:(state, data)=>{
      state.Token = data
    },
    set_Chain:(state, data)=>{
      state.chain = data
    },
    set_address:(state, data)=>{
      state.address = data
    },
    set_member:(state, data)=>{
      state.member = data
    },
  },
  actions:{
    getAccountData({ commit }){
     
    },
    getClear({ commit }){

      commit('set_member','')
    },
    getReset({ commit }){
      Loginout().then(res=>{
        setToken('')
        commit('set_member','')
        router.push('/login')
      })
 
    },
    getChain({ commit }){
     
    },
    getMemberView({ commit }){
      return new Promise((resolve, reject) => {
        MemberView().then(response => {
            const data = response.Data
            commit('set_member', data.member)
            resolve(data)
          }).catch(error => {
            reject(error)
          })
      })
    } ,
  },
  getters: {},
})
