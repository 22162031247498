<template>
  <div id="App">
    <router-view v-slot="{ Component }">
          <Transition name="fade-transform" mode="out-in">
            <keep-alive :key="key">
              <component :is="Component" />
          </keep-alive>
          <!-- <component :is="Component" v-else/> -->
          </Transition>
        <!-- <Transition name="fade-transform" mode="out-in">
          <component :is="Component" v-if="!route.meta.KeepAlive"></component>	
        </Transition>   -->
    </router-view>
    <van-back-top right="15px" bottom="10vh" />
  </div>
</template>

<script>
import { useRouter,useRoute } from 'vue-router'
import { onBeforeMount, ref,computed,watch } from 'vue'

export default {
  name: 'App',
  components: {
  },
  setup() {
    const router = useRouter()
		const route = useRoute();
    const key = ref('')
    watch(
			() => router.currentRoute.value,
			(newValue) => {
				let path = router.currentRoute.value.path
				if(path){
          // if(path=='/home'||path =='/'){
          //    key.value = path
          // }else{
          //   key.value = ''
          // }
          // if(['/helpCenter','/helpDetail','/payinfo','/addalipay','/addBank','/addUaddr','/szRod','/zzRod','/szDetail','/order','/orderDetail','/mall','/detail','/home','/tree','/study'].includes(path)){
          //   key.value =''
          // }else{
          //   key.value = path
          // }
				}
			},
			{ immediate: true }

		)
    onBeforeMount(() => {
			console.log(route)
			
    	})
    return {
			route,
      key
		}
  }
 
}
</script>
<style lang="less">
.van-field__control:disabled{
  color:#333!important;
  -webkit-text-fill-color:#333!important;
}
.van-field__body  input::-webkit-input-placeholder{
  color:#777777;
}
.van-field__body  input::-moz-input-placeholder{
  color:#777777;
}
.van-field__body  input::-ms-input-placeholder{
  color:#777777;
}
.van-field__body  input:-ms-input-placeholder{
  color:#777777;
}
.van-field__body input::placeholder{
  color:#777777!important;
}
.van-back-top{
background: #d33331!important;
}
.van-dialog__confirm, .van-dialog__confirm:active{
  color:#d33331!important;
}
.home-wrap{
  .van-nav-bar{
    background: #F4F9FC;
  }
  .van-notice-bar{
    padding:0!important;
  }
} 
.van-nav-bar .van-icon{
  color: #fff!important;
}
.wobble-active {
  animation: waggle 3s infinite;
}
.van-nav-bar{
  background:#d33331!important;
}
.van-theme-dark body {
  .van-nav-bar{
 background:black;
  }
  color: #fff;
  background-color: black;
  .van-grid{
    border-radius: 8px;
    background: #000;
    border: 1px solid #fff;
    overflow: hidden;
  }
  .van-grid-item__content{
    background: #000;
  }
  .van-grid-item__content{
    border:0 solid #fff;
    border-width: 0 1px 1px 0;
  }
  .van-grid-item:nth-last-child(2n+1) .van-grid-item__content{
    border-width:0  0 1px 0;
  }
  .van-grid-item:nth-last-child(1) .van-grid-item__content{
    border-width: 0  0 0 0;
  }
  .van-grid-item:nth-last-child(2) .van-grid-item__content{
    border-width: 0 1px 0 0;
  }
  .van-grid-item__content:after{
    border-width:0;
    border-color:#000;
  }
  .van-popover--dark .van-popover__content{
    background: #222;
  }
  .van-popover--dark .van-popover__arrow{
    color:#222;
  }
  .van-empty__description{
    color:#ddd;
  }
}
.van-grid{
  background: #000;
  .van-grid-item,.van-grid-item__content{
  background: #000;

  }
}
.login-wrap .van-checkbox__icon .van-icon{
  border: 1px solid #999!important;
}
.van-cell{

}
.radio-zf{
    width:100%!important;
}
.tan-box{
		width: 80vw;
		min-height: 26vh;
		padding-top: 20px;
		// background: url("@/assets/imgs/default_bg_pop.png") no-repeat left top / 100% 100%;
		overflow: hidden;
	}

@keyframes waggle {
  0%,
  7% {
    transform: rotate(0);
  }
  15%, 65% {
    transform: rotate(-15deg);
  }
  20%, 70% {
    transform: rotate(10deg);
  }
  25%, 75% {
    transform: rotate(-10deg);
  }
  30%, 80% {
    transform: rotate(6deg);
  }
  35%, 85% {
    transform: rotate(-4deg);
  }
  40%, 57%, 90%,
  100% {
    transform: rotate(0);
  }
}

.title-right{
.stats_icon{
  height: 12px;
}
.more_icon{
  width: 15px;
  height: 15px;
}
}
.form-list{
  .li{
    position: relative;
    line-height: 44px;
  }
  .li::after{
    transform: scaleY(0.8);
    position: absolute;
    bottom: 0;
    left: 0;
    content:'';
    width: 100%;
    height:1px;
    background: linear-gradient(135deg, #F2EEFF 27%, #E3EFFF 41%, #CEF2EB 50%, #EEF0FF 59%, #FFFFFE 69%, #E9EEFF 81%, #CFEAFF 90%, #C8F5DC 100%);
  }
}
.border-fff{
  border:1px solid #fff;
  border-radius: 8px;
}
.form-card{
  width: 92%;
  margin: 0 auto;
  .form{
    border: 1px solid #fff;
    border-radius: 8px;
    padding: 20px;
    .lable{
      width: 33%;
      line-height: 30px;
     text-align: justify;
     text-justify: inter-word; /* 适用于中英文混排 */
     text-align-last: justify;
    }
    .input{
      width:64%;
      border-bottom: 1px solid #fff;
      min-height: 30px;
      .van-cell{
        padding: 0 10px;
        background: #000;
        input{
          height: 30px;
          line-height: 30px;
          color:#fff;
          font-size: 15px;
        }
      }
    }
  }
}

.app-bg{
	max-height: 100vh;
	position: relative;
    .sjimg{
        width: 100%;
    }
    .absolute{
        z-index: 2;
        bottom: 70px;
        left: 0;

    }
	 
}

.foot-box{
      position: fixed;
      z-index: 3;
      height: 50Px;
      bottom: 0;
      left: 0;
      width: 100vw;
      background: #fff; 
  }
  .good-card{
    .good-card-item{
      border-bottom: 1px solid #eee;
      .goodimg{
        height: 80px;
      }
      .good-tit{
        height: 80px;
      }
    }
  }
  .van-field--disabled .van-field__label{
    color:#333!important;
  }
  .van-switch--on{
    background: #d33331!important;
  }
  .van-picker__confirm{
    color:#d33331!important;
  }
  .van-button--danger{
    background: #d33331!important;
    border:1px solid #d33331;
  }
  .tab-box{
    background: #f8f8f8;
    .tab{
      .active{
        position: relative;
        color:#d33331;
      }
      .active::after{
        content: ' ';
        position: absolute;
        bottom: -5px;
        left: 50%;
        transform: translateX(-50%);
        width: 20px;
        height: 2px;
        border-radius: 2px;
        background: #d33331;
      }
    }
    .tab1{
      background: #eaeaea;
      border-radius: 5px;
      color:#d33331;
      .item{
        border-radius: 5px;
        padding:5px ;
      }
      .active{
       background: #d33331;
       color:#fff;
      }
    }
  }
  .sopt-wrap{
    .van-cell{
        padding-left: 0;
    }
    #Count{
        font-size: 18px;
    }
}
.w-100{
  width: 100%;
}
</style>