import Cookies from './cookie'
const TokenKey = `Token` // 登录授权
const FId = `FId` // 邀请人id
const URL = 'URL' // 项目落地地址
const Lang = 'Lang' //语言
const inviteCode = 'inviteCode' //邀请人
const showNews = 'showNews' //
 const Stock = "Stock"
const Bank = 'bank'
const Psw = 'Psw'

const showGG = 'showGG'

export function getToken() {
  return Cookies.getItem(TokenKey)
}
export function setToken(token) {
  return Cookies.setItem(TokenKey, token, 86400)
}

export function removeToken() {
  return Cookies.removeItem(TokenKey)
}


export function setFId(key) {
  return Cookies.setItem(FId, key, { expires: 1 })
}
export function getFId() {
  return Cookies.getItem(FId)
}

export function setURL(key) {
  return Cookies.setItem(URL, key, 86400)
}
export function getURL() {
  return Cookies.getItem(URL)
}

export function getPsw(){
	return Cookies.getItem(Psw)
}

export function setPsw(key){
	return Cookies.setItem(Psw, key, 86400)
}

export function getLang(){
	return localStorage.getItem(Lang)
}

export function setLang(key){
	return localStorage.setItem(Lang,key)
}

export function getshowNews(){
	return localStorage.getItem(showNews)
}

export function setshowNews(key){
	return localStorage.setItem(showNews,key)
}

export function getshowGG(){
	return localStorage.getItem(showGG)
}

export function setshowGG(key){
	return localStorage.setItem(showGG,key)
}

export function getInviteCode(){
	return sessionStorage.getItem(inviteCode)
}

export function setInviteCode(key){
	return sessionStorage.setItem(inviteCode,key)
}

export function getStockinfo(){
	return sessionStorage.getItem(Stock)
}

export function setStockinfo(key){
	return sessionStorage.setItem(Stock,key)
}

export function getdBank(){
	return sessionStorage.getItem(Bank)
}

export function setdBank(key){
	return sessionStorage.setItem(Bank,key)
}


export const checkLogin = (fid) => {
  // return (window.location.href = `${
  //   import.meta.env.VITE_APIURL
  // }yx/wxlogin?spid=${import.meta.env.VITE_SPID}${
  //   fid ? '&f=' + fid : ''
  // }&url=${encodeURIComponent(location.href)}`)
}

export const MchAuth = async (pathName) => {
  // const token = getToken()
  // if (!token) return checkLogin()
  // // const url = encodeURIComponent(location.origin+ `/${import.meta.env.VITE_NAME}` + pathName)
  // let url
  // if (pathName) {
  //   const href = location.origin + location.pathname
  //   const index = href.lastIndexOf('/')
  //   url = href.replace(href.substring(index), pathName)
  // } else {
  //   url = location.href
  // }
  // const res = await authMch({ url })
  // // location.href = `${
  // //   import.meta.env.VITE_APIURL
  // // }yx/auth_mch?url=${url}&spid=${
  // //   import.meta.env.VITE_SPID
  // // }&t=${token}`
  // location.href = res.data.auth_url
  // return
}
