import request from '../utils/request'
import { showLoadingToast,closeToast,showToast } from 'vant';


//  初始化接口
export function ValidateCode(data) {
  return request({
    url: '/Qt/common/GetValidateCode',
    method: 'post',
    data,
  })
}

export function GetStockConfig(params) {
  return request({
    url: '/Qt/StockF/GetStockConfig',
    method: 'get',
    params,
  })
}

export function GetMyStock(params) {
  return request({
    url: '/Qt/StockF/GetMyStock',
    method: 'get',
    params,
  })
}

export function GetFHSubmit(params) {
  return request({
    url: '/Qt/StockF/GetFHSubmit',
    method: 'get',
    params,
  })
}

export function GetStockNew(params) {
  return request({
    url: '/Qt/StockF/GetStockNew',
    method: 'get',
    params,
  })
}

export function GetMeeting(params) {
  return request({
    url: '/Qt/MemberF/GetMeeting',
    method: 'get',
    params,
  })
}

export function MeetingSubmit(data) {
  showLoadingToast({
      duration:0,
      message:'提交中' +'...',
      forbidClick: true,
    });
  return request({
    url: '/Qt/MemberF/MeetingSubmit',
    method: 'post',
    data,
  })
}

export function StockSubmit(data) {
  showLoadingToast({
      duration:0,
      message:'提交中' +'...',
      forbidClick: true,
    });
  return request({
    url: '/Qt/StockF/StockSubmit',
    method: 'post',
    data,
  })
}


export function CarouselImage(params) {
  return request({
    url: '/Qt/DefaultF/GetCarouselImage',
    method: 'get',
    params,
  })
}

export function getCuctionStock(params) {
  return request({
    url: '/Qt/StockF/CuctionStock',
    method: 'get',
    params,
  })
}

export function Login(data) {
  showLoadingToast({
      duration:0,
      message:'登录中' +'...',
      forbidClick: true,
    });
  return request({
    url: '/Qt/DefaultF/Login',
    method: 'post',
    data,
  })
}

export function TelCodeLogin(data) {
  showLoadingToast({
      duration:0,
      message:'登录中' +'...',
      forbidClick: true,
    });
  return request({
    url: '/Qt/DefaultF/TelCodeLogin',
    method: 'post',
    data,
  })
}

export function SaveNiName(data) {
  showLoadingToast({
    duration:0,
    message:'提交中' +'...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MemberF/SaveNiName',
    method: 'post',
    data,
  })
}

export function SaveTel(data) {
  showLoadingToast({
    duration:0,
    message:'提交中' +'...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MemberF/SaveTel',
    method: 'post',
    data,
  })
}

export function SendOldTelCode(data) {
  return request({
    url: '/Qt/MemberF/SendOldTelCode',
    method: 'post',
    data,
  })
}

export function SendTransfersTelCode(data) {
  return request({
    url: '/Qt/FinanceF/SendTransfersTelCode',
    method: 'post',
    data,
  })
}


export function LoginCode(data) {
  return request({
    url: '/Qt/DefaultF/LoginCode',
    method: 'post',
    data,
  })
}


export function RegeditSumbit(data) {
  showLoadingToast({
    duration:0,
    message:'提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/DefaultF/RegeditSumbit',
    method: 'post',
    data,
  })
}

export function ForgetPasswordForTel(data) {
  return request({
    url: '/Qt/DefaultF/ForgetPasswordForTel',
    method: 'post',
    data,
  })
}

export function RegisterCode(data) {
  return request({
    url: '/Qt/DefaultF/RegisterCode',
    method: 'post',
    data,
  })
}

export function ForgetPasswordCode(data) {
  return request({
    url: '/Qt/DefaultF/ForgetPasswordCode',
    method: 'post',
    data,
  })
}

export function EditMemberLoginPwd(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MemberF/EditMemberLoginPwd',
    method: 'post',
    data,
  })
}

export function EditMemberSecondPwd(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MemberF/EditMemberSecondPwd',
    method: 'post',
    data,
  })
}

export function NewNews(params) {
  return request({
    url: '/Qt/DefaultF/NewNews',
    method: 'get',
    params,
  })
}

export function NewsInfo(params) {
  return request({
    url: '/Qt/DefaultF/GetNewsInfo',
    method: 'get',
    params,
  })
}

export function GetTradeMarketList(params) {
  return request({
    url: '/Qt/DefaultF/GetTradeMarketList',
    method: 'get',
    params,
  })
}

export function GetNoticeImage(params) {
  return request({
    url: '/Qt/DefaultF/GetNoticeImage',
    method: 'get',
    params,
  })
}

export function GainOrderDetail(params) {
  return request({
    url: '/Qt/FinanceF/GainOrderDetail',
    method: 'get',
    params,
  })
}

export function GetSignIn(params) {
  return request({
    url: '/Qt/MemberF/GetSignIn',
    method: 'get',
    params,
  })
}
export function toSignIn(data) {
  showLoadingToast({
    duration:0,
    message: '签到中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MemberF/SignIn',
    method: 'post',
    data,
  })
}
export function GetNewsList(params) {
  return request({
    url: '/Qt/NoticeF/GetNewsList',
    method: 'get',
    params,
  })
}

export function GetNewsDetail(params) {
  return request({
    url: '/Qt/NoticeF/GetNewsDetail',
    method: 'get',
    params,
  })
}


export function MyAddrEdit(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MemberF/MyAddrEdit',
    method: 'post',
    data,
  })
}

export function VerifiedSubmit(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MemberF/VerifiedSubmit',
    method: 'post',
    data,
  })
}

export function DelAddr(data) {
  showLoadingToast({
    duration:0,
    message: '删除中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MemberF/DelAddr',
    method: 'post',
    data,
  })
}

export function MyTeamList(params) {
  return request({
    url: '/Qt/MemberF/MyTeamList',
    method: 'get',
    params,
  })
}


export function GetMyAddrList(params) {
  return request({
    url: '/Qt/MemberF/GetMyAddrList',
    method: 'get',
    params,
  })
}

export function GetKFUrl(params) {
  
  return request({
    url: '/Qt/MemberF/GetKFUrl ',
    method: 'get',
    params,
  })
}

export function MyTeamCount(params) {
  
  return request({
    url: '/Qt/MemberF/MyTeamCount',
    method: 'get',
    params,
  })
}
export function GetBillflowList(params) {
  return request({
    url: '/Qt/FinanceF/GetBillflowList',
    method: 'get',
    params,
  })
}

export function MemberRegedit(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MemberF/RegeditSumbit',
    method: 'post',
    data,
  })
}



export function EditMemberView(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MemberF/EditMemberView',
    method: 'post',
    data,
  })
}

export function LoginPwdCode(data) {
  showLoadingToast({
    duration:0,
    message: '发送中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MemberF/MemberLoginPwdCode',
    method: 'post',
    data,
  })
}

export function SecondPwdCode(data) {
  showLoadingToast({
    duration:0,
    message: '发送中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MemberF/MemberLoginSecondPwdCode',
    method: 'post',
    data,
  })
}

export function getMemberView(params) {
  return request({
    url: '/Qt/MemberF/EditMemberView',
    method: 'get',
    params,
  })
}

export function getActiveted(params) {
  return request({
    url: '/Qt/MemberF/Activeted',
    method: 'get',
    params,
  })
}

export function ActivetedSubmit(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MemberF/Activeted',
    method: 'post',
    data,
  })
}

export function DelAlipay(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MemberF/DelAlipay',
    method: 'post',
    data,
  })
}



export function SendEditBankCode(data) {
  showLoadingToast({
    duration:0,
    message: '发送中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MemberF/SendEditBankCode',
    method: 'post',
    data,
  })
}


export function SendEditAlipayCode(data) {
  showLoadingToast({
    duration:0,
    message: '发送中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MemberF/SendEditAlipayCode',
    method: 'post',
    data,
  })
}




export function MemberView(params) {
  return request({
    url: '/Qt/MemberF/MemberView',
    method: 'get',
    params,
  })
}

export function BankListUrl(data) {
  return request({
    url: '/Qt/common/GetBankList',
    method: 'post',
    data,
  })
}


export function MyBankList(params) {
  return request({
    url: '/Qt/MemberF/GetMyBankList',
    method: 'get',
    params,
  })
}

export function GetMyAlipayList(params) {
  return request({
    url: '/Qt/MemberF/GetMyAlipayList',
    method: 'get',
    params,
  })
}

export function MyBankEdit(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MemberF/MyBankEdit',
    method: 'post',
    data,
  })
}


export function MyAlipayEdit(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MemberF/MyAlipayEdit',
    method: 'post',
    data,
  })
}

export function DelBank(data) {
  showLoadingToast({
    duration:0,
    message: '删除中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MemberF/DelBank',
    method: 'post',
    data,
  })
}

export function uploadimg(data) {
  showLoadingToast({
    duration:0,
    message: '上传中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/common/uploadimg',
    method: 'post',
    data,
  })
}


export function NoReadNotice(params) {
  return request({
    url: '/Qt/MemberF/GetMemberNoReadNotice',
    method: 'get',
    params,
  })
}

export function ReferralLink(params) {
  return request({
    url: '/Qt/MemberF/GetReferralLink',
    method: 'get',
    params,
  })
}


export function AwardsRecord(params) {
  return request({
    url: '/Qt/FinanceF/GainAwardsRecord',
    method: 'get',
    params,
  })
}

export function InMailList(params) {
  return request({
    url: '/Qt/NoticeF/GetInMailList',
    method: 'get',
    params,
  })
}

export function GetMsgDetail(params) {
  return request({
    url: '/Qt/NoticeF/GetMsgDetail',
    method: 'get',
    params,
  })
}


export function SendLetter(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/NoticeF/SendLetter',
    method: 'post',
    data,
  })
}

export function uploadimgBase64(data) {
  return request({
    url: '/Qt/common/uploadimgBase64',
    method: 'post',
    data,
  })
}



export function getSubmitWithdraw(params) {
  return request({
    url: '/Qt/FinanceF/SubmitWithdraw',
    method: 'get',
    params,
  })
}

export function SubmitWithdraw(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/FinanceF/SubmitWithdraw',
    method: 'post',
    data,
  })
}



export function SendWithdrawCode(data) {
  return request({
    url: '/Qt/FinanceF/SendWithdrawCode',
    method: 'post',
    data,
  })
}

export function GetJumpLinkUrl(params) {
  return request({
    url: '/Qt/DefaultF/GetJumpLinkUrl',
    method: 'get',
    params,
  })
}

export function Rechargeinfo(params) {
  return request({
    url: '/Qt/FinanceF/GetRechargeOnlineConfig',
    method: 'get',
    params,
  })
}


export function GetMyWeChatList(params) {
  return request({
    url: '/Qt/MemberF/GetMyWeChatList',
    method: 'get',
    params,
  })
}

export function GetSXHDesc(params) {
  return request({
    url: '/Qt/NoticeF/GetSXHDesc',
    method: 'get',
    params,
  })
}



export function PayActiveted(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MemberF/PayActiveted',
    method: 'post',
    data,
  })
}

export function RechargeOnlineSubmit(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/FinanceF/RechargeOnlineSubmit',
    method: 'post',
    data,
  })
}

export function MyWeChatEdit(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MemberF/MyWeChatEdit',
    method: 'post',
    data,
  })
}

export function DelWeChat(data) {
  showLoadingToast({
    duration:0,
    message: '删除中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MemberF/DelWeChat',
    method: 'post',
    data,
  })
}

export function SubmitExchange(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/FinanceF/SubmitExchange',
    method: 'post',
    data,
  })
}

export function getSubmitExchange(params) {
  return request({
    url: '/Qt/FinanceF/SubmitExchange',
    method: 'get',
    params,
  })
}

export function GetExchanges(params) {
  return request({
    url: '/Qt/FinanceF/GetExchanges',
    method: 'get',
    params,
  })
}

export function GetCenterBill(params) {
  return request({
    url: '/Qt/FinanceF/GetCenterBill',
    method: 'get',
    params,
  })
}

export function GetRemittances(params) {
  return request({
    url: '/Qt/FinanceF/GetRemittances',
    method: 'get',
    params,
  })
}

export function GetWithdraws(params) {
  return request({
    url: '/Qt/FinanceF/GetWithdraws',
    method: 'get',
    params,
  })
}



export function Loginout(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MemberF/Loginout',
    method: 'post',
    data,
  })
}


export function GetAppLink(params) {
  return request({
    url: '/Qt/DefaultF/GetAppLinkUrl',
    method: 'get',
    params,
  })
}


export function GetEPPrice(params) {
  return request({
    url: '/Qt/EPTradeF/GetEPPrice',
    method: 'get',
    params,
  })
}


export function GetCertification(params) {
  return request({
    url: '/Qt/MemberF/GetCertification',
    method: 'get',
    params,
  })
}


export function SendCertificationCode(data) {
  showLoadingToast({
    duration:0,
    message: '发送中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MemberF/SendCertificationCode',
    method: 'post',
    data,
  })
}

export function SubmitCertification(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MemberF/SubmitCertification',
    method: 'post',
    data,
  })
}



export function SubmitWithdraw2(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/FinanceF/SubmitWithdraw2',
    method: 'post',
    data,
  })
}


export function GeAppLinkUrl(params) {
  return request({
    url: '/Qt/DefaultF/GeAppLinkUrl',
    method: 'get',
    params,
  })
}

export function GeDownLoadUrl(params) {
  return request({
    url: '/Qt/DefaultF/GeDownLoadUrl',
    method: 'get',
    params,
  })
}


export function GetGoodsList(params) {
  return request({
    url: '/Qt/MallUF/GetGoodsList',
    method: 'get',
    params,
  })
}

export function GetGoodsInfo(params) {
  return request({
    url: '/Qt/MallUF/GetGoodsInfo',
    method: 'get',
    params,
  })
}


export function OnlyBuy(data) {
  return request({
    url: '/Qt/MallF/OnlyBuy',
    method: 'post',
    data,
  })
}

export function getSubmitOrder(params) {
  return request({
    url: '/Qt/MallF/SubmitOrder',
    method: 'get',
    params,
  })
}

export function SubmitOrder(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MallF/SubmitOrder',
    method: 'post',
    data,
  })
}

export function GetMallOrderList(params) {
  return request({
    url: '/Qt/MallF/GetMallOrderList',
    method: 'get',
    params,
  })
}

export function GetOrdeInfo(params) {
  return request({
    url: '/Qt/MallF/GetOrdeInfo',
    method: 'get',
    params,
  })
}

export function PayOrder(params) {
  return request({
    url: '/Qt/MallF/PayOrder',
    method: 'get',
    params,
  })
}

export function PayOrderMoney(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MallF/PayOrderMoney',
    method: 'post',
    data,
  })
}

export function CancelOrder(data) {
  showLoadingToast({
    duration:0,
    message: '取消中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MallF/CancelOrder',
    method: 'post',
    data,
  })
}


export function ConfirmGoods(data) {
  showLoadingToast({
    duration:0,
    message: '确认中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MallF/ConfirmGoods',
    method: 'post',
    data,
  })
}

export function SaveMallAddr(data) {
  showLoadingToast({
    duration:0,
    message: '保存中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MallF/SaveMallAddr',
    method: 'post',
    data,
  })
}

export function GetMallAddrList(params) {
  return request({
    url: '/Qt/MallF/GetMallAddrList',
    method: 'get',
    params,
  })
}

export function GetMallAddr(params) {
  return request({
    url: '/Qt/MallF/GetMallAddr',
    method: 'get',
    params,
  })
}

export function GetDefaultAddr(params) {
  return request({
    url: '/Qt/MallF/GetDefaultAddr',
    method: 'get',
    params,
  })
}

export function SetDefaultMallAddr(data) {
  showLoadingToast({
    duration:0,
    message: '设置中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MallF/SetDefaultMallAddr',
    method: 'post',
    data,
  })
}

export function DelMallAddr(data) {
  showLoadingToast({
    duration:0,
    message: '删除中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MallF/DelMallAddr',
    method: 'post',
    data,
  })
}

export function GetTree(params) {
  return request({
    url: '/Qt/TreeF/GetTree',
    method: 'get',
    params,
  })
}

export function SubmitBuy(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/TreeF/SubmitBuy',
    method: 'post',
    data,
  })
}

export function SubmitWater(data) {
  return request({
    url: '/Qt/TreeF/SubmitWater',
    method: 'post',
    data,
  })
}

export function GetHelpedConfig(params) {
  return request({
    url: '/Qt/HelpF/GetHelpedConfig',
    method: 'get',
    params,
  })
}

export function HelpedSubmit(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/HelpF/HelpedSubmit',
    method: 'post',
    data,
  })
}

export function GetHelpConfig(params) {
  return request({
    url: '/Qt/HelpF/GetHelpConfig',
    method: 'get',
    params,
  })
}

export function HelpSubmit(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/HelpF/HelpSubmit',
    method: 'post',
    data,
  })
}

export function GetMyTradeList(params) {
  return request({
    url: '/Qt/HelpF/GetMyTradeList',
    method: 'get',
    params,
  })
}

export function GetBuyList(params) {
  return request({
    url: '/Qt/HelpF/GetBuyList',
    method: 'get',
    params,
  })
}

export function GetSaleList(params) {
  return request({
    url: '/Qt/HelpF/GetSaleList',
    method: 'get',
    params,
  })
}

export function GetSaleRecordInfo(params) {
  return request({
    url: '/Qt/HelpF/GetSaleRecordInfo',
    method: 'get',
    params,
  })
}

export function GetBuyRecordInfo(params) {
  return request({
    url: '/Qt/HelpF/GetBuyRecordInfo',
    method: 'get',
    params,
  })
}


export function PayBuy(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/HelpF/PayBuy',
    method: 'post',
    data,
  })
}

export function PayConfirm(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/HelpF/Confirm',
    method: 'post',
    data,
  })
}

export function NConfirm(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/HelpF/NConfirm',
    method: 'post',
    data,
  })
}

export function GetUnsealInfo(params) {
  return request({
    url: '/Qt/MemberF/GetUnsealInfo',
    method: 'get',
    params,
  })
}

export function UnsealList(params) {
  return request({
    url: '/Qt/MemberF/UnsealList',
    method: 'get',
    params,
  })
}

export function SubmitApplyUnseal(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MemberF/SubmitApplyUnseal',
    method: 'post',
    data,
  })
}

export function GetMemberInfo(data) {
 
  return request({
    url: '/Qt/DefaultF/GetMemberInfo',
    method: 'post',
    data,
  })
}


export function getSubmitTransfers(params) {
  return request({
    url: '/Qt/FinanceF/SubmitTransfers',
    method: 'get',
    params,
  })
}

export function TransfersSubmit(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/FinanceF/SubmitTransfers',
    method: 'post',
    data,
  })
}

export function GetTransfersList(params) {
  return request({
    url: '/Qt/FinanceF/GetTransfersList',
    method: 'get',
    params,
  })
}

export function GetSaleOID(params) {
  return request({
    url: '/Qt/MemberF/GetSaleOID',
    method: 'get',
    params,
  })
}

export function GetBuyOID(params) {
  return request({
    url: '/Qt/MemberF/GetBuyOID',
    method: 'get',
    params,
  })
}

export function SubmitSeekHelp(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/HelpF/SubmitSeekHelp',
    method: 'post',
    data,
  })
}

export function GetBankNew(params) {
  return request({
    url: '/Qt/BankF/GetBankNew',
    method: 'get',
    params,
  })
}

export function GetBankInfo(params) {
  return request({
    url: '/Qt/BankF/GetBankInfo',
    method: 'get',
    params,
  })
}

export function GetSeekHelp(params) {
  return request({
    url: '/Qt/HelpF/GetSeekHelp',
    method: 'get',
    params,
  })
}

export function SeekHelpList(params) {
  return request({
    url: '/Qt/HelpF/SeekHelpList',
    method: 'get',
    params,
  })
}

export function GetRechargeOnlineConfig2(params) {
  return request({
    url: '/Qt/FinanceF/GetRechargeOnlineConfig2',
    method: 'get',
    params,
  })
}

export function GetCenterAddr(params) {
  return request({
    url: '/Qt/MemberF/GetCenterAddr',
    method: 'get',
    params,
  })
}

export function GetRechargeOnlineList(params) {
  return request({
    url: '/Qt/FinanceF/GetRechargeOnlineList',
    method: 'get',
    params,
  })
}

export function GetTradeConfig(params) {
  return request({
    url: '/Qt/EPTradeF/GetTradeConfig',
    method: 'get',
    params,
  })
}

export function BankIn(data) {
  showLoadingToast({
    duration:0,
    message: '存入中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/BankF/BankIn',
    method: 'post',
    data,
  })
}


export function BankOut(data) {
  showLoadingToast({
    duration:0,
    message: '取出中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/BankF/BankOut',
    method: 'post',
    data,
  })
}

export function ReceiveBankFH(data) {
  showLoadingToast({
    duration:0,
    message: '领取中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/BankF/ReceiveBankFH',
    method: 'post',
    data,
  })
}

export function TradeSale(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/EPTradeF/TradeSale',
    method: 'post',
    data,
  })
}

export function TradeBuy(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/EPTradeF/TradeBuy',
    method: 'post',
    data,
  })
}


export function RevokeBuy(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/EPTradeF/RevokeBuy',
    method: 'post',
    data,
  })
}

export function RevokeSale(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/EPTradeF/RevokeSale',
    method: 'post',
    data,
  })
}

export function GetEPTradeList(params) {
  return request({
    url: '/Qt/EPTradeF/GetEPTradeList',
    method: 'get',
    params,
  })
}

export function GetEPMyTradeList(params) {
  return request({
    url: '/Qt/EPTradeF/GetMyTradeList',
    method: 'get',
    params,
  })
}

export function GetRedConfig(params) {
  return request({
    url: '/Qt/RedF/GetRedConfig',
    method: 'get',
    params,
  })
}

export function GetMyRedList(params) {
  return request({
    url: '/Qt/RedF/GetMyRedList',
    method: 'get',
    params,
  })
}

export function ReceiveRedPacket(data) {
  showLoadingToast({
    duration:0,
    message: '正在抢...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/RedF/ReceiveRedPacket',
    method: 'post',
    data,
  })
}


export function GetGoldConfig(params) {
  return request({
    url: '/Qt/GoldF/GetGoldConfig',
    method: 'get',
    params,
  })
}

export function GetMyGoldList(params) {
  return request({
    url: '/Qt/GoldF/GetMyGoldList',
    method: 'get',
    params,
  })
}

export function GetHelpSubmit(params) {
  return request({
    url: '/Qt/GoldF/GetHelpSubmit',
    method: 'get',
    params,
  })
}

export function GetTransferSubmit(params) {
  return request({
    url: '/Qt/GoldF/GetTransferSubmit',
    method: 'get',
    params,
  })
}

export function GetTakeGoldSubmit(params) {
  return request({
    url: '/Qt/GoldF/GetTakeGoldSubmit',
    method: 'get',
    params,
  })
}

export function GetSaleSubmit(params) {
  return request({
    url: '/Qt/GoldF/GetSaleSubmit',
    method: 'get',
    params,
  })
}
export function GetMyOrderList(params) {
  return request({
    url: '/Qt/GoldF/GetMyOrderList',
    method: 'get',
    params,
  })
}
export function GetMyHelpList(params) {
  return request({
    url: '/Qt/GoldF/GetMyHelpList',
    method: 'get',
    params,
  })
}
export function GetMyTransferList(params) {
  return request({
    url: '/Qt/GoldF/GetMyTransferList',
    method: 'get',
    params,
  })
}


export function GoldSubmit(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/GoldF/GoldSubmit',
    method: 'post',
    data,
  })
}

export function GoldHelpSubmit(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/GoldF/HelpSubmit',
    method: 'post',
    data,
  })
}

export function GoldTransferSubmit(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/GoldF/TransferSubmit',
    method: 'post',
    data,
  })
}

export function TakeGoldSubmit(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/GoldF/TakeGoldSubmit',
    method: 'post',
    data,
  })
}

export function GoldConfirm(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/GoldF/Confirm',
    method: 'post',
    data,
  })
}

export function GetMyOrderInfo(params) {
  return request({
    url: '/Qt/GoldF/GetMyOrderInfo',
    method: 'get',
    params,
  })
}


export function GetRechargeOnlineSwitch(params) {
  return request({
    url: '/Qt/FinanceF/GetRechargeOnlineSwitch',
    method: 'get',
    params,
  })
}

export function GetRechargeOnlineConfig3(params) {
  return request({
    url: '/Qt/FinanceF/GetRechargeOnlineConfig3',
    method: 'get',
    params,
  })
}

export function GetAXZ(params) {
  return request({
    url: '/Qt/HelpF/GetAXZ',
    method: 'get',
    params,
  })
}


export function GetRechargeOnlineList2(params) {
  return request({
    url: '/Qt/FinanceF/GetRechargeOnlineList2',
    method: 'get',
    params,
  })
}