import { KeepAlive } from 'vue'
import { createRouter, createWebHistory,createWebHashHistory} from 'vue-router'

const routes = [
  // {
  //   path: '/',
  //   redirect: '/home',
  // },
 
  
  {
    path: '/',
    name: 'Index',
    redirect: '/home',
    component: () => import(/* webpackChunkName: "home" */ './pages/index/index.vue'),
    children:[
      {
        path: '/home',
        component: () => import('@/pages/index/home.vue'),
        name: 'Home',
        meta:{
          KeepAlive:false
        }

      },
     
      {
        path: '/study',
        name: 'Study',
        meta:{
          KeepAlive:false
        },
        component: () =>
          import('@/pages/index/study.vue'),
      },
      {
        path: '/sxh',
        name: 'Sxh',
        meta:{
          KeepAlive:false
        },
        component: () =>
          import('@/pages/index/sxh.vue'),
      },
      {
        path: '/my',
        name: 'My',
        meta:{
          KeepAlive:false
        },
        component: () =>
          import('@/pages/my/my.vue'),
      },
      
    ]
  },
  // {
  //   path: '/sxh',
  //   name: 'Sxh',
  //   meta:{
  //     KeepAlive:false
  //   },
  //   component: () =>
  //     import('@/pages/index/sxh.vue'),
  // },
  {
    path: '/sbank',
    name: 'Sbank',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/index/sbank.vue'),
  },
  {
    path: '/applyHelp',
    name: 'ApplyHelp',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/index/applyHelp.vue'),
  },
  {
    path: '/mall',
    name: 'MallIndex',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/mall/index.vue'),
  },
  {
    path: '/tree',
    name: 'Tree',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/index/tree.vue'),
  },
  {
    path: '/grab',
    name: 'Grab',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/index/grab.vue'),
  },{
    path: '/grabRod',
    name: 'GrabRod',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/index/grabRod.vue'),
  },
  
  {
    path: '/trade',
    name: 'Trade',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/index/trade.vue'),
  },
  {
    path:'/bsRod',
    name: 'BsRod',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/bsRod.vue'),
  },
  {
    path: '/realname',
    name: 'RealName',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/realname.vue'),
  },
  {
    path:'/rechargeline',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/rechargeline.vue'),
  },
  {
    path: '/addwx',
    name: 'Addwx',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/addwx.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/login/login.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/login/register.vue'),
  },
  {
    path: '/forgot',
    name: 'Forgot',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/login/forgot.vue'),
  },
  {
    path: '/detail',
    name: 'MallDetail',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/mall/detail.vue'),
  },
  {
    path: '/payMoney',
    name: 'PayMoney',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/mall/payMoney.vue'),
  },
  {
    path: '/apply',
    name: 'Apply',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/index/apply.vue'),
  },{
    path: '/qzRod',
    name: 'QzRod',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/index/qzRod.vue'),
  },{
    path: '/qzDetail',
    name: 'QzDetail',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/index/qzDetail.vue'),
  },{
    path: '/support',
    name: 'Support',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/index/support.vue'),
  },
  {
    path: '/supportDetail',
    name: 'SupportDetail',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/index/supportDetail.vue'),
  },
  {
    path: '/grod',
    name: 'Grod',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/index/grod.vue'),
  },{
    path: '/grodDetail',
    name: 'GrodDetail',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/index/grodDetail.vue'),
  },
  {
    path: '/studyDetail',
    name: 'StudyDetail',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/index/studyDetail.vue'),
  },
  {
    path: '/helpDetail',
    name: 'HelpDetail',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/index/helpDetail.vue'),
  },
  {
    path: '/helpCenter',
    name: 'HelpCenter',
    meta:{
      KeepAlive:true
    },
    component: () =>
      import('@/pages/index/helpCenter.vue'),
  },
  {
    path: '/szRod',
    name: 'SzRod',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/index/szRod.vue'),
  },{
    path: '/zzRod',
    name: 'ZzRod',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/index/zzRod.vue'),
  },
  {
    path: '/szDetail',
    name: 'SzDetail',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/index/szDetail.vue'),
  },
  {
    path: '/team',
    name: 'Team',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/index/team.vue'),
  },
  {
    path: '/exchange',
    name: 'Exchange',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/index/exchange.vue'),
  },
  {
    path: '/dhRod',
    name: 'DhRod',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/index/dhRod.vue'),
  },
  {
    path: '/placeOrder',
    name: 'PlaceOrder',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/mall/placeOrder.vue'),
  },
  {
    path: '/info',
    name: 'Info',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/info.vue'),
  },{
    path: '/bill',
    name: 'Bill',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/bill.vue'),
  },{
    path: '/dbill',
    name: 'Dbill',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/dbill.vue'),
  },{
    path: '/recharge',
    name: 'Recharge',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/recharge.vue'),
  },
  {
    path: '/buyRod',
    name: 'BuyRod',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/buyRod.vue'),
  },
  {
    path: '/memberInfo',
    name: 'MemberInfo',
    meta:{
      KeepAlive:true
    },
    component: () =>
      import('@/pages/my/memberInfo.vue'),
  },
  {
    path: '/extract',
    name: 'Extract',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/extract.vue'),
  },{
    path: '/inviteLink',
    name: 'InviteLink',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/inviteLink.vue'),
  },{
    path: '/transfer',
    name: 'Transfer',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/transfer.vue'),
  }, {
    path: '/transferrod',
    name: 'TransferRod',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/transferrod.vue'),
  }, {
    path: '/txRod',
    name: 'TxRod',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/txRod.vue'),
  },
  
  {
    path: '/wallet',
    name: 'Wallet',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/wallet.vue'),
  },{
    path: '/payinfo',
    name: 'PayInfo',
    meta:{
      KeepAlive:true
    },
    component: () =>
      import('@/pages/my/payinfo.vue'),
  }, {
    path: '/addalipay',
    name: 'Addalipay',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/addalipay.vue'),
  }, 
  {
    path: '/addUaddr',
    name: 'AddUaddr',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/addUaddr.vue'),
  },
  {
    path: '/hregister',
    name: 'HRegister',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/hregister.vue'),
  },  
  {
    path: '/addBank',
    name: 'AddBank',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/addBank.vue'),
  }, {
    path: '/editPws',
    name: 'EditPws',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/editPws.vue'),
  }, {
    path: '/editaPws',
    name: 'EditaPws',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/editaPws.vue'),
  },
  {
    path: '/unseal',
    name: 'Unseal',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/unseal.vue'),
  }, {
    path: '/yjRod',
    name: 'YjRod',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/index/yjRod.vue'),
  },{
    path: '/unsealRod',
    name: 'UnsealRod',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/unsealRod.vue'),
  },{
    path: '/addrList',
    name: 'AddrList',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/addrList.vue'),
  }, {
    path: '/order',
    name: 'Order',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/mall/order.vue'),
  },{
    path: '/addAddr',
    name: 'AddAddr',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/addAddr.vue'),
  },{
    path: '/orderDetail',
    name: 'OrderDetail',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/mall/orderDetail.vue'),
  },{
    path: '/gold',
    name: 'gold',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/gold/index.vue'),
  },{
    path: '/takeGoods',
    name: 'takeGoods',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/gold/takeGoods.vue'),
  },{
    path: '/donation',
    name: 'donation',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/gold/donation.vue'),
  },{
    path: '/give',
    name: 'give',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/gold/give.vue'),
  },{
    path: '/giveRod',
    name: 'giveRod',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/gold/giveRod.vue'),
  },{
    path: '/takeRod',
    name: 'takeRod',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/gold/takeRod.vue'),
  },{
    path: '/donationRod',
    name: 'donationRod',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/gold/donationRod.vue'),
  },{
    path: '/takeDetail',
    name: 'takeDetail',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/gold/takeDetail.vue'),
  },{
    path: '/meeting',
    name: 'meeting',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/index/meeting.vue'),
  },{
    path:'/stock',
    name: 'stock',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/stock/index.vue'),
  },{
    path:'/applyStock',
    name: 'applyStock',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/stock/applyStock.vue'),
  },{
    path:'/certificate',
    name: 'certificate',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/stock/certificate.vue'),
  },{
    path:'/czRod',
    name: 'czRod',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/czRod.vue'),
  }
]

export const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

// 0-9，a-z, length:8
const randomString = () => {
  return Math.random().toString(36).slice(-8)
}

router.beforeEach((to, from, next) => {
  
  if (to.name === '/helpCenter') {
    // 如果是从非当前页面跳转到需要刷新的页面，则强制刷新
    if (from.name === '/home' ) {
      // 强制重新获取数据或重置状态
      next(true);
    } else {
      // 如果是从当前页面跳转到当前页面，可以选择保持原状
      next();
    }
  } else {
    // 其他页面正常跳转
    next(true);
  }
})

router.afterEach(() => {
  // 隐藏菜单
  // hideMenuItems()
})
