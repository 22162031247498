import {router} from '@/router'
import { showToast } from 'vant'


export function _isMobile() {      
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)      
    return flag;    
}
export function isPhoneNumber(str) {
    var myreg = /^\d+$/;
    if (!myreg.test(str)) {
        return false;
    } else {
        return true;
    }
}

export function getQueryVariable(variable)
{
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i=0;i<vars.length;i++) {
        var pair = vars[i].split("=");
        if(pair[0] == variable){return pair[1];}
    }
    return(false);
}

export function starPhone(phoneNum){
    let str = String(phoneNum),
        strLen = str.slice(0, 3) +'****' + str.slice(str.length-4);
    return strLen;
}


export function goLink(url,query){
    router.push({
        path:url,
        query:query
    })
}

export function toToast(msg){
    showToast(msg)
}

export function getformToken(str){
    if(str){
        return str.substr(0,4)+'...'+str.substr(str.length-4)
    }else{
        return str
    }
  }

  export function checkSecPassword(password){
	//6位数字密码
	var myreg = /^\d{6}$/;
	if (!myreg.test(password)) {
		return false;
	} else {
		return true;
	}
}
export function checkPassword(password){
	//字母和数字组合的6-12位密码
	var myreg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/;
	if (!myreg.test(password)) {
		return false;
	} else {
		return true;
	}
}

export function openUrl(url) {
    let openInBrowser = (url) => {
        if (window.android) {
            window.android.callAndroid("open," + url)
            return;
        }
        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.nativeExt) {
            window.webkit.messageHandlers.nativeExt.postMessage({ msg: 'open,' + url })
            return;
        }
        var u = navigator.userAgent;
        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if (isiOS) {
            window.location.href = url;
            return;
        }
        window.open(url)
    }
    openInBrowser(url);
    return
  }


// 压缩图片
// 压缩前将file转换成image对象
export function readImg(file) {
    return new Promise((resolve, reject) => {
        const image = new Image();
        const reader = new FileReader();
        reader.onload = function (e) {
            image.src = e.target.result
        };
        reader.onerror = function (e) {
            reject(e)
        };
        reader.readAsDataURL(file);

        image.onload = function () {
            resolve(image)
        };
        image.onerror = function (e) {
            reject(e)
        };
    })
}



export function compressImg(file) {

    let quality = 1
    if (file.file.size / (1024 * 1024) > 1) {
        quality = 1 / Math.ceil(file.file.size / (1024 * 1024)) // 默认到1m以下
    }
    return new Promise((resolve, reject) => {

        let fileType = file.file.type
        let imgResult = ''
        let reader = new FileReader()
        reader.readAsDataURL(file.file)

        reader.onload = function () {
            let img = new Image() //先创建图片对象
            img.src = this.result
            let canvas = document.createElement('canvas')
            let ctx = canvas.getContext('2d')
            img.onload = function () { //图片加载完后
              // 做适配
              let width = 500
              if (img.width < 500) {
                width = img.width
              }
              canvas.width = width
              canvas.height = (img.height / img.width) * width
              ctx.drawImage(img, 0, 0, canvas.width, canvas.height)  //绘制图像；把大图片画在一张小画布上，压缩就这么实现了
              // 返回base64
              //quality表示导出的图片质量,只要导出为jpg和webp格式的时候此参数才有效果，默认值是0.92
              imgResult = canvas.toDataURL(fileType, quality)
              resolve(imgResult)
              
              // 这时可能后端要求我们传文件格式图片 base64转Blob
            //   const blobImg = converrVase64UrlToBlob(imgResult , fileType)
            //   resolve(blobImg)
            }
          }
          reader.onerror = function (error) {
            reject(error)
          }

    })
}

function converrVase64UrlToBlob (base64, mimeType){
    // mimeType 图片类型，例如 mimeType='image/png'
    const bytes = window.atob(base64.split(',')[1]) // atob方法用于解码base64
    // 创建一个长度为 bytes.length 的 buffer(一个二进制文件), 它会分配一个 16 字节（byte）的连续内存空间，并用 0 进行预填充。
    const ab = new ArrayBuffer(bytes.length)

    // Uint8Array —— 将 ArrayBuffer 中的每个字节视为 0 到 255 之间的单个数字（每个字节是 8 位）。这称为 “8 位无符号整数”。
    const ia = new Uint8Array(ab)
    for (let i = 0; i < bytes.length; i++) {
    // 更改里面的初始化内容
    ia[i] = bytes.charCodeAt(i)
    }
    // 创建blob格式数据，并传入二进制文件和文件原本类型
    return new Blob([ia], { type: mimeType })

}
